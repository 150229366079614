import Cookies from 'universal-cookie';

const cookies = new Cookies();

if (cookies.get('Language') != null && cookies.get('Language') != '') {
  var index = cookies.get('Language');
} else {
  cookies.set('Language', 0);
  var index = cookies.get('Language');
}

function Change(event) {
  var index = 0;
  switch (event) {
    case 'EN':
      cookies.set('Language', 1);
      index = cookies.get('Language');
      return index;

    case 'ES':
      cookies.set('Language', 2);
      index = cookies.get('Language');
      return index;

    case 'PT':
      cookies.set('Language', 0);
      index = cookies.get('Language');
      return index;
  }
}

export default function Idioma(event) {
  if (event != null) {
    index = Change(event);
  }

  // return index;
  return 0;
}
